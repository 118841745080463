import {
  ExceptionService,
  SearchBarInput,
  ToastService,
  useGetCalls,
} from "@/shared";
import styles from "./CallsJournal.module.scss";
import { CallsTable, FilteredTableFields, TopPanel } from "./components";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Box } from "@mui/material";

export interface FilteredField {
  columnField: string;
  columnValue: string | boolean;
}

export const CallsJournalPage = () => {
  const boxRef = useRef<HTMLDivElement>(null);

  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs(new Date()));
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs(new Date()));

  const [filteredFields, setFilteredFields] = useState<FilteredField[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const { calls, refetch } = useGetCalls({
    date_start: dateFrom?.format("YYYY-MM-DD") || "",
    date_end: dateTo?.format("YYYY-MM-DD") || "",
  });

  const { control, watch } = useForm({
    values: {
      searchValue: "",
    },
  });

  const searchValue = watch("searchValue");

  const filteredCallsBySearch = calls.filter((elem) =>
    elem.subscriber.toLowerCase().includes(searchValue.toLowerCase())
  );

  const [boxHeight, setBoxHeight] = useState(0);

  useEffect(() => {
    if (boxRef.current) {
      setBoxHeight(boxRef.current.offsetHeight);
    }
  }, [boxRef]);

  const refetchCalls = async () => {
    try {
      setIsLoading(true);
      await refetch();
    } catch (error) {
      ToastService.onDanger({ title: ExceptionService.errorResolver(error) });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Box ref={boxRef} sx={{ mb: 2 }}>
        <TopPanel
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
          refetchCalls={refetchCalls}
          isLoading={isLoading}
        />

        <Controller
          control={control}
          name="searchValue"
          render={({ field: { value, onChange } }) => (
            <SearchBarInput value={value} onChange={onChange} />
          )}
        />
      </Box>

      <FilteredTableFields
        filteredFields={filteredFields}
        setFilteredFields={setFilteredFields}
      />

      <CallsTable
        calls={searchValue ? filteredCallsBySearch : calls}
        boxHeight={boxHeight}
        isLoading={isLoading}
        filteredFields={filteredFields}
        setFilteredFields={setFilteredFields}
      />
    </div>
  );
};
