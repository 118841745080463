import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Tooltip, Typography } from "@mui/material";
import styles from "./MenuDrawer.module.scss";
import { useNavigate } from "react-router-dom";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";

import {
  useAuthStoreSelectors,
  useMenuDrawerStoreSelectors,
} from "@/shared/stores";
import { Drawer, DrawerHeader, menuIcon, MenuItem } from "./helpers";
import { Colors } from "@/styles";

interface MenuDrawerProps {
  children: React.ReactNode;
}

export const MenuDrawer = ({ children }: MenuDrawerProps) => {
  const [open, setOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number>(0);

  const navigate = useNavigate();

  const logout = useAuthStoreSelectors.use.logout();

  const setIsMenuDrawerOpen =
    useMenuDrawerStoreSelectors.use.setIsMenuDrawerOpen();

  const menuItems = [
    { text: "Журнал дзвінків", onClick: () => navigate("/") },
    { text: "Пропущені виклики", onClick: () => navigate("/missed-calls") },
    { text: "Аналіз дзвінків", onClick: () => navigate("/calls-analysis") },
    { text: "Перелік абонентів", onClick: () => navigate("/subscribers-list") },
    { text: "Вийти", onClick: () => logout() },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
    setIsMenuDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setIsMenuDrawerOpen(false);
  };

  const handleMenuItemClick = (index: number, onClick: () => void) => {
    setActiveIndex(index);
    onClick();
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          sx={{
            display: "flex",
            flexDirection: open ? "row" : "column",
            justifyContent: open ? "space-between" : "center",
            alignItems: "center",
            px: 2,
            pt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              userSelect: "none",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            <Box className={styles.container} sx={{ mb: open ? 0 : "10px" }}>
              <Typography
                sx={{ fontWeight: "bold", color: "#fff", fontSize: 22 }}
              >
                T
              </Typography>
            </Box>

            <Box sx={{ display: open ? "block" : "none" }}>
              <Typography
                sx={{ fontWeight: "bold", color: "#000", fontSize: 18 }}
              >
                MyEasyTele
              </Typography>
            </Box>
          </Box>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            sx={{ ml: 0, mb: open ? 0 : 1, fontSize: 20 }}
          >
            {open ? <RxDoubleArrowLeft /> : <RxDoubleArrowRight />}
          </IconButton>
        </DrawerHeader>

        <Box
          sx={{
            display: open ? "none" : "flex",
            justifyContent: "center",
          }}
        >
          <Divider sx={{ width: "75%" }} />
        </Box>

        <List>
          {menuItems.map(
            (item, index) =>
              index !== 3 && (
                <ListItem
                  key={item.text}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <Tooltip
                    title={item.text}
                    placement="right"
                    arrow
                    disableHoverListener={open}
                  >
                    <ListItemButton
                      onClick={() => {
                        handleMenuItemClick(index, item.onClick);
                      }}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        backgroundColor:
                          activeIndex === index ? Colors.Primary : "inherit",
                        color: activeIndex === index ? "#fff" : "#393939",
                        transition: "all .2s ease",
                        "&:hover": {
                          backgroundColor: "#c7dfe9",
                          color: "#fff",
                          "& .MuiListItemIcon-root": {
                            color: "#fff",
                          },
                        },
                        mt: index === menuItems.length - 1 ? 1 : 0,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: activeIndex === index ? "#fff" : "#393939",
                          fontSize: "22px",
                          transition: "all .1s ease",
                        }}
                      >
                        {menuIcon(index)}
                      </ListItemIcon>

                      <MenuItem
                        primary={item.text}
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItemButton>
                  </Tooltip>

                  {index === menuItems.length - 3 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 1,
                      }}
                    >
                      <Divider sx={{ width: "85%" }} />
                    </Box>
                  )}
                </ListItem>
              )
          )}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        {children}
      </Box>
    </Box>
  );
};
