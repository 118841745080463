import { Box } from "@mui/material";
import { FilteredField } from "../CallsJournalPage";
import styles from "../CallsJournal.module.scss";
import { IoIosClose } from "react-icons/io";

interface FilteredTableFieldsProps {
  filteredFields: FilteredField[];
  setFilteredFields: (value: FilteredField[]) => void;
}

export const FilteredTableFields = ({
  filteredFields,
  setFilteredFields,
}: FilteredTableFieldsProps) => {
  return (
    <Box
      sx={{
        mb: filteredFields.length ? 2 : 0,
        display: "flex",
        gap: "12px",
      }}
    >
      {filteredFields.map((elem) => (
        <Box
          className={styles.selected_field}
          onClick={() =>
            // @ts-ignore
            setFilteredFields((prev: FilteredField[]) =>
              prev.filter((field) => field.columnField !== elem.columnField)
            )
          }
        >
          <Box>
            {elem.columnField === "primary" ? "№1 - Yes" : elem.columnValue}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IoIosClose color="white" size={20} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
