import { Call, DD_MM_YYYY_TIME } from "@/shared";
import { GridColDef } from "@mui/x-data-grid";
import { FcVlc } from "react-icons/fc";
import { CallResult, CallType } from "../models";
import { GiRotaryPhone } from "react-icons/gi";
import { HiArrowLongDown, HiArrowLongUp } from "react-icons/hi2";
import { Box } from "@mui/material";
import { FcVoicePresentation } from "react-icons/fc";
import { FcOnlineSupport } from "react-icons/fc";
import { FcCallTransfer } from "react-icons/fc";
import { FcClock } from "react-icons/fc";
import { FcVoicemail } from "react-icons/fc";
import { FcCheckmark } from "react-icons/fc";
import { FaArrowUpLong } from "react-icons/fa6";
import { FaArrowDownLong } from "react-icons/fa6";

import styles from "../CallsJournal.module.scss";

export const tableColumns = (
  calls: Call[],
  isTechFields: boolean,
  onContextMenu: (
    event: React.MouseEvent,
    columnField: string,
    columnValue: string | boolean
  ) => void,
  sortDirection: "asc" | "desc",
  toggleSort: () => void
) => {
  const columns: GridColDef<(typeof calls)[number]>[] = [
    {
      field: "calldate",
      renderHeader: () => (
        <div className={styles.table_header}>
          <div style={{ fontWeight: 500 }}>Дата</div>
          <div
            style={{ marginTop: 4, marginLeft: 10, cursor: "pointer" }}
            onClick={toggleSort}
          >
            {sortDirection === "asc" ? <FaArrowUpLong /> : <FaArrowDownLong />}
          </div>
        </div>
      ),
      flex: 1,
      sortable: false,
      valueGetter: (_, row: Call) => DD_MM_YYYY_TIME(row.calldate) || "",
    },
    {
      field: "call_result",
      renderHeader: () => <FcCallTransfer fontSize={18} />,
      flex: 0.4,
      sortable: false,
      renderCell: ({ row }) => (
        <Box
          style={{ cursor: "context-menu" }}
          onContextMenu={(event) =>
            onContextMenu(event, "call_result", row.call_result)
          }
        >
          {row.call_result === CallResult.ANSWERED ? (
            <GiRotaryPhone
              color="green"
              fontSize={18}
              style={{ marginTop: 8 }}
            />
          ) : (
            <GiRotaryPhone color="red" fontSize={18} style={{ marginTop: 8 }} />
          )}
        </Box>
      ),
    },
    {
      field: "call_type",
      renderHeader: () => <FcVlc fontSize={18} />,
      flex: 0.4,
      sortable: false,
      renderCell: ({ row }) => (
        <Box
          style={{ cursor: "context-menu" }}
          onContextMenu={(event) =>
            onContextMenu(event, "call_type", row.call_type)
          }
        >
          {row.call_type === CallType.OUTGOING ? (
            <HiArrowLongUp
              color="#1d7de6"
              fontSize={18}
              style={{ marginTop: 8 }}
            />
          ) : (
            <HiArrowLongDown
              color="#e6e600"
              fontSize={18}
              style={{ marginTop: 8 }}
            />
          )}
        </Box>
      ),
    },
    {
      field: "call_tracking",
      headerName: "Джерело",
      flex: 0.8,
      sortable: false,
      renderCell: () => <Box>@@@</Box>,
    },
    {
      field: "client",
      renderHeader: () => (
        <div className={styles.table_header}>
          <FcVoicePresentation fontSize={18} />
          <Box sx={{ fontWeight: "500" }}>Абонент, Тел</Box>
        </div>
      ),
      flex: 1.4,
      sortable: false,
      renderCell: ({ row }) => (
        <Box
          style={{ cursor: "context-menu" }}
          onContextMenu={(event) =>
            onContextMenu(event, "subscriber", row.subscriber)
          }
          className={styles.table_header}
        >
          {row.subscriber !== row.src && row.subscriber !== row.dst && (
            <Box>{`${row.subscriber},` || ""}</Box>
          )}
          <Box>{row.call_type === "Вих" ? row.dst || "" : row.src || ""}</Box>
        </Box>
      ),
    },
    {
      field: "operator",
      renderHeader: () => (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <div className={styles.table_header}>
            <FcOnlineSupport fontSize={16} />
            <Box sx={{ fontWeight: "500" }}>Оператор</Box>
          </div>
        </Box>
      ),
      flex: 0.5,
      sortable: false,
      renderCell: ({ row }) => (
        <Box
          style={{ justifyContent: "flex-end", cursor: "context-menu" }}
          onContextMenu={(event) =>
            onContextMenu(event, "operator", row.operator)
          }
          className={styles.table_header}
        >
          <Box>{row.operator}</Box>
        </Box>
      ),
    },
    {
      field: "primary",
      renderHeader: () => (
        <div className={styles.table_header}>
          <Box sx={{ fontWeight: "500" }}>№1</Box>
        </div>
      ),
      flex: 0.4,
      sortable: false,
      renderCell: ({ row }) => (
        <Box
          onContextMenu={(event) =>
            onContextMenu(event, "primary", row.primary)
          }
          className={styles.table_header}
          style={{ justifyContent: "flex-end", cursor: "context-menu" }}
        >
          <Box>
            {row.primary && (
              <FcCheckmark fontSize={18} style={{ marginTop: 6 }} />
            )}
          </Box>
        </Box>
      ),
    },
    {
      field: "duration",
      renderHeader: () => (
        <div className={styles.table_header}>
          <FcClock fontSize={18} />
          <Box sx={{ fontWeight: "500" }}>Викл.</Box>
        </div>
      ),
      flex: 0.5,
      sortable: false,
      renderCell: ({ row }) => (
        <div
          className={styles.table_header}
          style={{ justifyContent: "flex-end" }}
        >
          <Box>{row.duration}</Box>
        </div>
      ),
    },
    {
      field: "billsec",
      renderHeader: () => (
        <div className={styles.table_header}>
          <FcClock fontSize={18} />
          <Box sx={{ fontWeight: "500" }}>Розм.</Box>
        </div>
      ),
      flex: 0.5,
      sortable: false,
      renderCell: ({ row }) => (
        <div
          className={styles.table_header}
          style={{ justifyContent: "flex-end" }}
        >
          <Box>{row.billsec}</Box>
        </div>
      ),
    },
    {
      field: "record_file",
      renderHeader: () => (
        <div className={styles.table_header}>
          <FcVoicemail fontSize={18} />
          <Box sx={{ fontWeight: "500" }}>Запис розмови</Box>
        </div>
      ),
      flex: 1.6,
      sortable: false,
      renderCell: ({ row }) => {
        const handleClick = () => {
          const audioUrl = row.link;
          const newWindow = window.open(audioUrl, "_blank");
          if (newWindow) {
            newWindow.focus();
          }
        };
        return (
          <Box
            onClick={handleClick}
            sx={{
              textDecoration: "underline",
              height: "60px",
              cursor: "pointer",
            }}
          >
            {row.recordingfile}
          </Box>
        );
      },
    },

    ...(isTechFields
      ? [
          {
            field: "uniqueid",
            renderHeader: () => <Box sx={{ fontWeight: "600" }}>Uniqueid</Box>,
            flex: 1,
            sortable: false,
          },
          {
            field: "counterparty",
            renderHeader: () => (
              <Box sx={{ fontWeight: "600" }}>Counterparty</Box>
            ),
            flex: 0.8,
            sortable: false,
          },
        ]
      : []),
  ];

  return columns;
};
